.failure
    {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: $red;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;

    .inner
        {
        opacity: 1;
        max-width: 600px;
        .writeButton {margin-top: 60px;}
        }
    h3
        {
        text-align: center;
        color: $white;
        font-weight: 700;
        font-size: $xxlarge;
        }
    &.failure-enter
        {
        background: transparent;
        box-shadow: inset 0px 0px 0px 2px transparent;
        .inner { opacity: 0; }
        }

    &.failure-enter.failure-enter-active
        {
        box-shadow: inset 0px 0px 20px 1000px $red;
        background: $red;
        transition: box-shadow .3s ease-in, background .3s .1s ease-in;
        .inner { opacity: 1; transition: opacity .5s .2s ease-out; }
        }
    }



.tweet
    {
    border: 1px solid $white;
    color: $white;
    text-decoration: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-family: Merriweather;
    text-align: left;
    line-height: 150%;
    display: block;
    margin-bottom: 30px;
    position: relative;
    opacity: .5;
    margin-left: 60px;
    &.narrow {margin: 0px 120px;}
    &.dark
        {
        border: 1px solid #1D8EF0;
        opacity: 1;
        color: $grey;
        &:before { border-right: 10px solid #1D8EF0; }
        &:after { color: #1D8EF0; }
        &:hover
            {
            color: $white;
            background: #1D8EF0;
            }
        }
    &:hover { opacity: 1; }
    &:before
        {
        content: "";
        display: block;
        position: absolute;
        left: -20px;
        top: calc(50% - 10px);
        border: 10px solid transparent;
        border-right: 10px solid $white;
        }
    &:after
        {
        @include icon;
        content: '\74';
        display: block;
        position: absolute;
        font-size: $xlarge;
        left: -60px;
        color: $white;
        bottom: calc(50% - 20px);
        }
    }
