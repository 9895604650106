$red: #F45653;
$dark: rgb(26,32,38);
$grey: #51565D;
$blue: #4F6079;
$light-blue: #6089C6;
$light-grey: #6F7985;
$green: #86D045;
$white: #FFFEFA;
$off-white: mix($white, $light-grey, 80%);
$off-off-white: mix($white, $light-grey, 60%);
