@font-face {
  font-family: 'mdwa';
  src: url('/fonts/mdwa.eot?62057485');
  src: url('/fonts/mdwa.eot?62057485#iefix') format('embedded-opentype'),
    url('/fonts/mdwa.woff2?62057485') format('woff2'),
    url('/fonts/mdwa.woff?62057485') format('woff'),
    url('/fonts/mdwa.ttf?62057485') format('truetype'),
    url('/fonts/mdwa.svg?62057485#mdwa') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin icon {
  font-family: "mdwa";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  @include icon
}

.icon-left-open:before {
  content: '\3c';
}

/* '&lt;' */
.icon-list:before {
  content: '\3d';
}

/* '=' */
.icon-help:before {
  content: '\3f';
}

/* '?' */
.icon-beaker:before {
  content: '\62';
}

/* 'b' */
.icon-ok:before {
  content: '\63';
}

/* 'c' */
.icon-download:before {
  content: '\64';
}

/* 'd' */
.icon-github:before {
  content: '\67';
}

/* 'g' */
.icon-cursor:before {
  content: '\69';
}

/* 'i' */
.icon-quote-left:before {
  content: '\6c';
}

/* 'l' */
.icon-spin:before {
  content: '\6f';
}

/* 'o' */
.icon-pencil:before {
  content: '\70';
}

/* 'p' */
.icon-quote-right:before {
  content: '\72';
}

/* 'r' */
.icon-twitter:before {
  content: '\74';
}

/* 't' */
.icon-grid:before {
  content: '\78';
}

/* 'x' */
.icon-fullscreen:before {
  content: '\66';
}

/* 'f' */
.icon-night-mode:before {
  content: '\6e';
}

/* 'n' */


.icon-mdwa {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: $red;
  vertical-align: -10%;
  box-shadow: inset 0px 0px 0px 0.125em $red, inset 0px 0px 0px 0.208em $white;
}
